import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "bootstrap-icons/font/bootstrap-icons.css";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {
  const [deals, setDeals] = React.useState(null);
  const [showResults, setResults] = React.useState(20);
  const [startSearch, setSearch] = React.useState(false);
  const [filterKey, setFilter] = React.useState(''); // filter for results, default: ''

  const fetchData = () => {
    setSearch(true);
    setTimeout(fetchData2, 300)
  }
  const fetchData2 = () => {
    fetch('/.netlify/functions/getDeals', { method: 'POST'})
    .then((res) => res.text())
    .then((data) => setDeals(data));
  }

  const moreResults = () => {
    setResults(showResults + 20);
  }

  const changeFilterBusiness = () => {
    setFilter('Busi');
    console.log('Current filter:', filterKey);
  }

  const changeFilterFirst = () => {
    setFilter('First');
    console.log('Current filter:', filterKey);
  }

  const changeFilterAll = () => {
    setFilter('');
    console.log('Current filter:', filterKey);
  }

  const stringDate = (var1) => {
    return var1.substring(8,10).concat('.').concat(var1.substring(5,7)).concat('.').concat(var1.substring(0,4));
  }

  const heuteRaw = new Date();
  const heute = heuteRaw.getDate() + '.' + (heuteRaw.getMonth() + 1) + '.' + heuteRaw.getFullYear()


  const icons = {'Travel-Dealz': 'https://travel-dealz.de/app/uploads/sites/2/cropped-Travel-Dealz-Icon-192x192.png',
                  'yhbu': 'https://youhavebeenupgraded.boardingarea.com/wp-content/uploads/2021/01/cropped-Logoneu-180x180.jpg',
                  'Reisetopia': 'https://reisetopia.de/wp-content/uploads/2018/06/reisetopia-Logo.png',
                  'Frankfurtflyer': 'https://i0.wp.com/www.frankfurtflyer.de/wp-content/uploads/2019/12/cropped-Frankfurtflyer_icon-3.jpg',
                  'jetbeds': 'https://www.jetbeds.com/apple-touch-icon.png'};

  return (
  <Layout>
    <Seo title="Die besten Flugdeals" />
    
    <section id="willkommen" className="d-flex align-items-center">
      <div className="container text-center banner-text">
        <h1 className="company-font">
          <span className="fw-bold">Budgettrips.de</span> &#9992; Heisse Flugdeals für Business, Eco und First
        </h1>
        <p className="lead">Einfach günstige Flüge finden</p>
      </div>
    </section>

    <section className="py-3 bg-light">
      <div className="container">
        <div class="row d-flex justify-content-around my-4">
          
          <div class="col-8 col-lg-3 my-2">
            <div class="card">
              <StaticImage src="../images/deals-finden.png" class="card-img-top" alt="Die besten deals finden"/>
              <div class="card-body">
                <p class="card-text">Wir finden für Sie die besten Angebote für Business, Eco und First</p>
              </div>
            </div>
          </div>

          
          <div class="col-8 col-lg-3 my-2">
            <div class="card">
              <StaticImage src="../images/nur-deals.png" class="card-img-top" alt="Nur Deals, kein Schnickschnack"/>
              <div class="card-body">
                <p class="card-text">Nur Deals - ohne Bla Bla</p>
              </div>
            </div>
          </div>

          <div class="col-8 col-lg-3 my-2">
            <div class="card">
              <StaticImage src="../images/traumhaft-reisen.png" class="card-img-top" alt="Deal finden, traumhaft verreisen"/>
              <div class="card-body">
                <p class="card-text">Angebote suchen, Deal finden, traumhaft verreisen</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    
    {/* <section id="zertifikate" className="bg-secondary text-white">

      <div class="d-flex justify-content-around container">
        
        <div class="d-flex flex-column text-center">
          <i class="bi bi-award fs-1"></i>
          <span>Geprüfte Quellen</span>
        </div>
        <div class="d-flex flex-column text-center">
          <i class="bi bi-gem fs-1"></i>
          <span>Validierte Angebote</span>
        </div>
        <div class="d-flex flex-column text-center">
          <i class="bi bi-brightness-high fs-1"></i>
          <span>Sonnige Preise</span>
        </div>
        <div class="d-flex flex-column text-center">
          <i class="bi bi-search-heart fs-1"></i>
          <span>Mit Liebe gesucht</span>
        </div>
    
      </div>
    
    </section> */}

    <section id="budgettrips-deals" className="py-5 container text-center">
  
      <div class="row d-flex justify-content-center mb-5">
          
          <div class="col-12 col-lg-6 text-start d-flex align-items-center">
            <div>
              <h2>Zeig mir die besten Deals &#128293;</h2>
              <p class="lead">Deals erkunden, günstig buchen und mit Freude fliegen</p>
            
              {startSearch? 
                (!deals?
                <div>
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Wir suchen...</span>
                  </div>
                  <p>Wir durchsuchen die aktuellen Deals für Dich...</p>
                </div>:
                <div>
                  <p>Wir haben aktuelle Deals für Dich gefunden</p>
                </div>
                )
                : 
                <button className="btn btn-primary btn-no-focus shadow-lg" onClick={fetchData}><i class="bi bi-search"></i> Aktuelle Deals suchen</button>
              }
              
            </div>

          </div>

          <div class="d-none d-lg-block col-8 col-lg-4 text-start">
            {/* {!deals?
              <StaticImage src="../images/deals-liste.png" alt="Die besten Deals gelistet"/>
              :
              <StaticImage src="../images/deals-liste.png" alt="Die besten Deals gelistet"/>
            } */}
            <StaticImage className="w-100" src="../images/deals-anzeigen.png" alt="Die besten Deals anzeigen"/>
          </div>

      </div>

            <div class="card m-2 visually-hidden">
              <div class="card-body d-flex align-items-center">
                <div class="me-2">
                  <h4>&#9992;</h4>
                </div>
                <div class="d-inline card-text text-start">
                  Deal: Günstige Business-, Eco- und First-Flüge in die ganze Welt zu unschlagbaren Preisen
                </div>
              </div>
              <div class="card-footer d-flex justify-content-between align-items-center">
                <span class="badge rounded-pill text-dark found-badge">mit <i class="bi bi-heart"></i> recherchiert</span>
                {/* <button onClick={fetchData} class="btn btn-sm btn-outline-dark">Alle Deals anzeigen <i class="bi bi-airplane"></i></button> */}
              </div>
            </div>

      <h2 className="mt-4">Aktuelle Deals</h2> 

      <p className="mb-0">
        Sortiert nach: <p class="badge rounded-pill text-dark found-badge"><i class="bi bi-sort-down"></i> gefunden am</p>
      </p>

      <div>
        Filter: 
        {/* <button className="badge rounded-pill text-dark mx-1" onClick={changeFilterBusiness}><i class="bi bi-search"></i> Nur Business-Deals</button>
        <button className="badge rounded-pill text-dark mx-1" onClick={changeFilterFirst}><i class="bi bi-search"></i> Nur First-Deals</button>
        <button className="badge rounded-pill text-dark mx-1" onClick={changeFilterAll}><i class="bi bi-search"></i> Alle Deals</button> */}
        <button className={`badge rounded-pill mx-1 ${filterKey === '' ? "active" : "text-dark"}`} onClick={changeFilterAll}><i class="bi bi-search"></i> Alle Deals</button>
        <button className={`badge rounded-pill mx-1 ${filterKey === 'Busi' ? "active" : "text-dark"}`} onClick={changeFilterBusiness}><i class="bi bi-search"></i> Business-Deals</button>
        <button className={`badge rounded-pill mx-1 ${filterKey === 'First' ? "active" : "text-dark"}`} onClick={changeFilterFirst}><i class="bi bi-search"></i> First-Deals</button>
        {/* <p class="badge rounded-pill text-dark text-muted filter-badge mx-1"><i class="bi bi-sort-down"></i> Startflughafen: alle</p>
        <p class="badge rounded-pill text-dark text-muted filter-badge mx-1"><i class="bi bi-sort-down"></i> Zielregion: alle</p>
        <p class="badge rounded-pill text-dark text-muted filter-badge mx-1"><i class="bi bi-sort-down"></i> Buchungsklasse: alle</p>
        <p class="badge rounded-pill text-dark text-muted filter-badge mx-1"><i class="bi bi-sort-down"></i> Datum: alle</p> */}
      </div>

      {!deals && startSearch?
          <div>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Wir suchen...</span>
            </div>
          </div>:
          <div></div>
      }
      
      {startSearch?
      <div>
      </div>:
      <div>
        <div class='row d-flex justify-content-around'>

          <div class="col col-12 col-md-6">
            <div class="card m-2">
              <div class="card-body d-flex align-items-center">
                <div class="me-2">
                  <h4>&#9992;</h4>
                </div>
                <div class="placeholder-glow">
                  <span class="placeholder placeholder w-100">Deal: Günstige Business-, Eco- und First-Flüge</span>
                  <span class="placeholder w-100">Deal: Günstige Business-, Eco- und First-Flüge</span>
                </div>
              </div>
              <div class="card-footer d-flex justify-content-between align-items-center">
                <span class="badge rounded-pill text-dark found-badge"><i class="bi bi-search"></i> {heute}</span>
                <button class="btn btn-sm btn-outline-dark" disabled>Zum Deal <i class="bi bi-airplane"></i></button>
              </div>
            </div>
          </div>

        </div>

        <div class='row d-flex justify-content-around'>  
          <div class="col col-12 col-md-6">
            {/* <h2><i class="bi bi-three-dots text-muted"></i></h2> */}
            <button className="btn btn-outline-primary btn-no-focus" onClick={fetchData}><i class="bi bi-search"></i> Deals anzeigen</button>
          </div>
        </div>
      </div>
      }

      <div id="budgettrips-aktuelle-deals" class='row'>
        {!deals ?
        <div></div> :
          Object.values(JSON.parse(deals).slice(0,showResults)).filter(function(deal) {
            if (deal.title.search(filterKey) < 0) {
              return false; // skip
            }
            return true;
          }).map((deal) =>

            <div class="col col-12 col-md-6">
              <div class="card m-2">
                <div class="card-body d-flex align-items-center">
                  
                  <img src={icons[deal.source_name]} class="d-inline img-fluid float-start rounded source-img" alt={deal.source_name}/>
                  
                  <p class="d-inline card-text text-start">{deal.title}</p>
                </div>
                <div class="card-footer d-flex justify-content-between align-items-center">
                <span class="badge rounded-pill text-dark found-badge"><i class="bi bi-search"></i> {stringDate(deal.timeInserted)}</span>
                  <a href={deal.link} target="blank" class="btn btn-sm btn-outline-dark">Zum Deal <i class="bi bi-airplane"></i></a>
                </div>
              </div>
            </div>

        )      
        }
        {!deals || showResults >= 160 ?
          <div></div>:
          <div class="d-grid gap-2 d-md-block">
            <button className="btn btn-primary" onClick={moreResults}>Weitere Deals anzeigen</button>
          </div>
        }
       
      </div>
    </section>

    <section id="budgettrips-deal-alarm" className="py-5 bg-light">
      <div class="container text-center">

        <h4>Mit unserem Deal-Alarm &#128293; keine Angebote verpassen &#128262; &#127865; &#128525; </h4>
      
        <div className='row d-flex justify-content-around my-4'>

          <div className='col-4 d-flex flex-column align-items-center'>
            <a href="https://instagram.com/budgettrips.de/" target="blank" className="text-decoration-none">
              <StaticImage src="../images/inst-logo.png" className="step1-img" alt="Auf Instagram folgen"/> 
            </a>
            <p><strong>Schritt 1</strong></p>
            <p>Folge Budgettrips.de bei <a href="https://instagram.com/budgettrips.de/" target="blank" className="text-decoration-none">Instagram</a></p>
          </div>
          <div className='col-4 d-flex flex-column align-items-center'>
            <i class="bi bi-bell step23-icon"></i>
            <p><strong>Schritt 2</strong></p>
            <p>Erhalte täglich die heissesten Deals</p>
          </div>
          <div className='col-4 d-flex flex-column align-items-center'>
            <i class="bi bi-emoji-heart-eyes step23-icon"></i>
            <p><strong>Schritt 3</strong></p>
            <p>Geniesse Deinen Traumflug</p>
          </div>
        </div>
      </div>
    </section>

    <section id="budgettrips-team" className="py-5">
      
      <div class="container text-center">
        <h2 class="m-2">Für Ihren besten Flug geben wir alles &#128170;</h2>
        <div class="row d-flex justify-content-around my-4">
          
          <div class="col-xs-8 col-md-3 my-2">
            <div class="card">
              <StaticImage src="../images/mit-liebe-gesucht.png" class="card-img-top" alt="Mit Liebe gesucht"/>
              <div class="card-body">
                <p class="card-text">Mit viel Liebe suchen wir nach den besten Deals für Sie.</p>
              </div>
            </div>
          </div>

          
          <div class="col-xs-8 col-md-3 my-2">
            <div class="card">
              <StaticImage src="../images/budgettrips-team.png" class="card-img-top" alt="Budgettrips Team"/>
              <div class="card-body">
                <p class="card-text">Ein motiviertes und junges Team mit Liebe zum Detail.</p>
              </div>
            </div>
          </div>

          <div class="col-xs-8 col-md-3 my-2">
            <div class="card">
              <StaticImage src="../images/zufriedene-kunden.png" class="card-img-top" alt="Zufriedene Kunden"/>
              <div class="card-body">
                <p class="card-text">Wir lieben es, unsere Kunden glücklich zu sehen.</p>
              </div>
            </div>
          </div>

        </div>

        <blockquote class="blockquote">
          <p>«Unsere Nutzer erleben traumhafte Ferien und sparen dabei mit den besten Flugangeboten - das spornt uns an.
            Für die heissesten Flugdeals geben wir alles.»
          </p>
        </blockquote>

      </div>
      
    </section>
  </Layout>)
}

export default IndexPage
